<template>
  <!-- 加车按钮 -->
  <button
    v-if="showAddBagBtn"
    :class="[
      'product-card__add-btn',
    ]"
    role="button"
    :aria-label="language.SHEIN_KEY_PC_14634"
    @click.stop="addBag"
    @keydown.enter.stop="addBag"
  >
    <template v-if="!$slots.addToBagBtn">
      <i 
        v-if="addBagBtnStyle === 'default'" 
        class="sh_pc_sui_icon sh_pc_sui_icon_addtocart_20px_222 product-card__add-bag"
      ></i>
      <AddBagAddText v-else-if="addBagBtnStyle === 'AddText'" />
    </template>
    <slot
      v-else
      name="addToBagBtn"
    >
    </slot>
  </button>
</template>

<script setup>
import { inject, toRefs, computed } from 'vue'
import AddBagAddText from './AddBagAddText.vue'

const emit = defineEmits([
  'openQuickAdd'
])

const props = defineProps({
  soldOut: {
    type: Boolean,
    default: false,
  }
})

const { soldOut } = toRefs(props)

const config = inject('config', () => {})
const language = inject('language', () => {})

const addBagBtnStyle = config?.style?.addBagBtnStyle ?? 'default'

const showAddBagBtn = computed(() => {
  return config.showAddBagBtn && !soldOut.value
})

const addBag = (e) => {
  emit('openQuickAdd', e)
}

</script>

<style lang="less">
.product-card {
  &__add-btn {
    display: flex;
    color: #000;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    border: 1px solid var(--product-card____add-btn-border-color, #222);
    background-color: var(--product-card____add-btn-bg, #fff);
    border-radius: 5000px;
    padding: 2px 10px;
    cursor: pointer;

    > span {
      isolation: isolate;
    }
    
    &:hover::before {
      transform: scale(1.08);
      border-width: 1.5px;
    }
    &:active::before {
      width: 100%;
      transform: scale(1);
      border-width: 1px;
    }
    &:hover::before, &:active::before {
      transition: width .15s ease, height .15s ease;
    }
  }
}
</style>
