<template>
  <p 
    class="product-card__selling-proposition-text"
    :class="containerClassName"
    :style="props.style"
  >
    <img
      v-if="icon"
      class="selling-proposition__text-icon"
      :src="icon"
    />
    <i 
      v-else-if="suiIcon" 
      :class="{
        'sh_pc_sui_icon': true, 
        [suiIcon]: suiIcon 
      }"
    ></i>{{ text }}
  </p>
</template>

<script name="ProductCardSellingPropositionText" setup>
import { computed } from 'vue'
// <!-- 文本类型组件 -->

// 包含以下类型
// 低库存
// 用户行为标签
// 用户评论关键词
// 优质评论
// 人工卖点
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  fontStyle: {
    type: String,
    default: ''
  },
  style: {
    type: Object,
    default: () => {
      return {}
    }
  },
  icon: {
    type: String,
    default: ''
  },
  suiIcon: {
    type: String,
    default: ''
  },
})


const containerClassName = computed(() => {
  return {
    'font-gray': props.fontStyle === 'gray',
    'font-golden': props.fontStyle === 'golden'
  }
})

</script>

<style lang="less" scoped>
[lang=ar] .product-card__selling-proposition-text  {
  font-size: 13px;
}

.product-card__selling-proposition-text {
  text-align: left;
  .line-camp(1);
  max-width: 100%;
  height: var(--swiper-container-height, 16px);
  line-height: var(--swiper-container-height, 16px);
  font-size: 14px;

  &.font-gray {
    color: var(--sui-color-gray-dark-3, #767676);
  }
  &.font-golden {
    color: #CC6E00;
  }

  .selling-proposition__text-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 2px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .sh_pc_sui_icon {
    margin-right: 2px;
    vertical-align: middle;
  }
}
</style>
