<template>
  <div 
    class="s-swiper-item" 
  >
    <slot></slot>
  </div>
</template>


<script>
export default {
  name: 'SwiperItem'
}
</script>

<style lang="less">
.s-swiper-item {
  flex: 0 0 100%;
  width: 100%;

  z-index: 10;
}
</style>
