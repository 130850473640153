<template>
  <swiper-item>
    <ProductCardSellingPropositionStar 
      v-if="type === 'star'"
      :star-data="itemData.starData"
    />
    <ProductCardSellingPropositionText
      v-else-if="type === 'text'"
      :style="style"
      :font-style="fontStyle"
      :text="text"
      :sui-icon="suiIcon"
    />
    <slot v-else-if="itemData.type === 'slot'"></slot>
  </swiper-item>
</template>

<script name="ProductCardSellingPropositionSlideItem" setup>

import swiperItem from 'public/src/pages/components/Swiper/SwiperItem.vue'
import ProductCardSellingPropositionText from './ProductCardSellingPropositionText.vue'
import ProductCardSellingPropositionStar from './ProductCardSellingPropositionStar.vue'

const props = defineProps({
  itemData: {
    type: Object,
    default() {
      return {
        type: ''
      }
    }
  }
})

const type = props?.itemData?.type ?? ''
const text = props?.itemData?.textData?.text ?? ''
const fontStyle = props?.itemData?.textData?.fontStyle ?? ''
const style = props?.itemData?.style ?? {}
const suiIcon = props?.itemData?.textData?.suiIcon ?? ''

</script>


<style lang="less" scoped>
.product-card__selling-proposition-swiper {
  --swiper-container-height: 0.42rem;
  height: var(--swiper-container-height, 0.42rem);
}
.product-card__selling-proposition-container {
  margin: 1px 0;
}
</style>
