<template>
  <div class="product-card__selling-proposition-star">
    <ul 
      class="star-icon-list" 
      v-html="star_html"
    ></ul>
    <!-- <p class="average-text">
      {{ comment_rank_average }}
    </p> -->
    <p class="start-text">
      ({{ comment_num_str }})
    </p>
  </div>
</template>

<script name="ProductCardSellingPropositionStar" setup>
// 星级评论

// eslint-disable-next-line vue/no-setup-props-destructure
const { starData } = defineProps({
  starData: {
    type: Object,
    default() {
      return {}
    }
  },
  showAverage: {
    type: Boolean,
    default: false
  },
})

const star_html = starData?.star_html
const comment_num_str = starData?.comment_num_str
// const comment_rank_average = showAverage ? starData?.comment_rank_average : ''

</script>

<style lang="less" scoped>
// 星级评论 start
.product-card__selling-proposition-star {
  .flexbox();
  .align-center();

  .average-text {
    height: 16px;
    line-height: 18px;
    vertical-align: middle;
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    margin-right: 1px;
  }

  .star-icon-list {
    height: 16px;
    .flexbox();
    .align-center();
    margin-right: var(--single-star-margin-right, 0px);
  }

  .star-icon-item {
    margin-right: 2px;
  }

  .start-text {
    height: 16px;
    line-height: 18px;
    vertical-align: middle;
    
    font-size: 12px;

    color: var(--sui-color-gray-dark-2, #666);
    font-weight: var(--single-star-font-weight);
  }
}
// 星级评论 end

</style>
