<template>
  <i
    v-if="showIcon"
    class="sh_pc_sui_icon sh_pc_sui_icon_add_12px_222 add-bag-icon"
  ></i>
  <span
    v-else
    class="add-btn-text"
  >
    {{ text }}
  </span>
</template>

<script name="AddBagAddText" setup>
import { inject } from 'vue'

const language = inject('language', () => {})

const text = language?.SHEIN_KEY_PC_29848 || 'Add'

// 长度大于4显示加号icon
const showIcon = text.length > 4
</script>

<style lang="less" scoped>
.add-btn-text {
  line-height: 12px;
  font-weight: bold;
  font-size: 12px;
  color: var(--product-card____add-btn-color, #222);
}
</style>
